.ledenSections {

    display: flex;
    flex-direction: column;
    position: relative;
    /* padding-top: 10%; */
    /* margin-bottom: auto; */
    width: 100%;
    height: max-content;
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 10vh; */

}


.leden {
    /* height: 350px; */

    /* display: table-column; */
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    /* margin-top: 0%; */
    margin-left: auto;
    margin-right: auto;
    width: 90vw;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-family: "LatoRegular";
    height: 1vh;
    padding: 1%;

}
