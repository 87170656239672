.jumpTo {
    text-align: center;
    /* margin-left:auto;
    margin-right:auto; */
    /* padding-left: 1px; */
    left: 0;
    right: 0;
    width: 50vw;
    /* margin-top: -5%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    z-index: 2;
    height: 50vh;
    /* margin-top: 10%; */
    /* margin-bottom: auto; */
}

.shadow {
    -webkit-box-shadow: -8px 6px 34px -6px rgba(0, 0, 0, 0.75) !important;
    -moz-box-shadow: -8px 6px 34px -6px rgba(0, 0, 0, 0.75) !important;
    box-shadow: -8px 6px 34px -6px rgba(0, 0, 0, 0.75) !important;

}

.track-parent {

    width: 100vw;
    height: 90vh;

}


.image-track .image {
    width: 50%;
    height: 56vmin;
    object-fit: cover;
    object-position: center;
}

.image-track {
    overflow-x: scroll;
    display: flex;
    gap: 4vmin;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    width: 100vw;

}


.teamSections {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40vw, 1fr));
    gap: 0px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10vh;
    padding-top: 2vh;
}

.teamDiv {
    bottom: 0;
    padding-top: 1%;
    position: absolute;
    background-color: rgba(55,108,210,.7);
    font-size: 140%;
    margin: auto;
    text-align: center;
    z-index: 10;
    color: white;
    font-family: RobotoBold;
    -webkit-text-stroke: .1px black;
    height: content-fit;
    width: 100%;
    border-radius: 0px 0px 10px 10px;
}

.teams {
    opacity: 0;
    visibility: hidden;
    transition: opacity 750ms ease-in-out, transform 750ms ease-in-out;
    will-change: opacity, visibility;
    position: relative;
    overflow: hidden;
}

.imageHover {
    transition: transform .3s ease;
}

.imageHover:hover {
    transform: scale(1.2);
}

.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
    display: flex;
}

.hashButton {

    padding-top: 14px;
    margin-top: 5px;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: rgb(0, 29, 125);
    border-radius: 0 5px 5px 0;
    color: white;


}

.link:visited {
    color: white;
}