.header {

    background: linear-gradient(-20deg, #376cd2 0%, #376cd2 55%, #ffffff 55%, #ffffff 100%);
    height: 8vh;
    max-height: 60px;
    width: 100vw;
    position: fixed;
    font-family: "LatoBold";
    font-size: calc(15px + 1vw);
    -webkit-font-smoothing: antialiased;
    font-variation-settings: "wght" 450;
    top: 0px;
    border-radius: 0px 0px 3px 3px;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 4px 6px black;
    z-index: 1;
    flex-shrink: 0;

}

.headerSpace {
    height: 8vh;
    max-height: 60px;
    width: 100vw;
    flex-grow: 1;
}

.content {
    flex-grow: 1;
    padding: 1em;
}

.imageText {

    position: absolute;
    padding-left: 1%;
    width: 60vw;
    height: 100%;
    top: 0;
    font-family: "RobotoBold";
    color: white;
    display: flex;
    flex-direction: row;
    z-index: 1;

}

.imageText h1 {

    position: absolute;
    font-size: calc(1px + 1vw);
    width: 100%;
    height: 100%;
    font-family: verdana;
    font-weight: 700;
    color: #000000;
    margin-left: 5%;
    top: 0;

}

.headerItems {

    z-index: 10;
    position: absolute;
    width: 35vw;
    height: 100%;
    min-width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    right: 0;
    top: 30%;
    margin-right: 5%;
}

.overlay {

    /* padding-top: 10px; */
    text-align: center;
    color: white;
    position: absolute;
    font-size: 10px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.4);
    /* margin-top: 3vh; */
    bottom: 100px;
    /* margin-bottom: auto; */
    width: 10vw;
    height: 25%;
    max-height: 200px;
}

.sidebar {

    padding-top: 15px;
    padding-left: 10px;
    position: fixed;
    font-size: 10px;
    right: 0px;
    top: 0px;
    background-color: rgba(0, 0, 0, 0);
    z-index: 2;
    min-width: 75px;
    width: 5vw;
    height: 100%;
    transition: width .5s cubic-bezier(0.5, 0.5, 0.4, 1.2), background-color .5s ease-in-out;
    /* text-align: center; */

}

.sidebar.open {

    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    width: 60%;

}

.menuItems {

    display: flex;
    flex-direction: column;
    height: max-content;
    width: 50%;
    position: relative;
    margin-left: 20%;
    margin-right: auto;
    margin-top: 10px;
    opacity: 0;
    transition: opacity .5s ease-in-out;

}

.menuItems.show {

    opacity: 1;

}

.menuButton {
    opacity: 1;
    position: absolute;
    padding-top: 10px;
    cursor: pointer;
    display: inline-block;
}

.menuButton::after {
    display: block;
    content: '';
    height: 14px;
    width: 40px;
    border-top: 3px solid #090157;
    border-bottom: 3px solid #090157;
}

.menuButton::before {
    display: block;
    content: '';
    height: 10px;
    width: 40px;
    border-top: 3px solid #090157;
}

.footer {
    margin-top: 80px;
    color: white;
    background-color: #376cd2;
    /* position: relative; */
    /* left: 0;
    bottom: 0; */
    height: fit-content;
    min-height: 25vh;
    text-align: center;
    overflow:hidden;
    align-content: center;
    width: 100vw;
    flex-shrink: 0;
}


.footer img {
    height: 100%;
    max-height: 75px;
    min-width: 75px;
}


  

