@font-face {
    font-family: "RobotoBold";
    src: url('./design/fonts/Roboto-Bold.ttf');
}

@font-face {
    font-family: "RobotoRegular";
    src: url('./design/fonts/Roboto-Regular.ttf');
}

@font-face {
    font-family: "RobotoThin";
    src: url('./design/fonts/Roboto-Thin.ttf');
}

@font-face {
    font-family: "LatoRegular";
    src: url('./design/fonts/Lato/Lato-Regular.ttf');
}

@font-face {
    font-family: "LatoBold";
    src: url('./design/fonts/Lato/Lato-Bold.ttf');
}

@font-face {
    font-family: "LatoThin";
    src: url('./design/fonts/Lato/Lato-Thin.ttf');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bolder;
    src: url('./design/fonts/OpenSans-Regular.ttf');
}

#webpack-dev-server-client-overlay {
    display: none;
}

body {
    margin: 0;
    /* font-family: "LatoRegular"; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgb(0, 0, 0);
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
    /* height: fit-content;
    min-height: 100vh; */
    display: flex;
    flex-direction: column;
}

#root {
    font-family: 'Open Sans' !important;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
