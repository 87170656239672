.main {
    margin-top: 15vh;
    padding-bottom: 5vh;
}

@keyframes fadeInUp {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}

.pdf {
    height: 100vh;
}

.slider {
    filter: blur(0px);;
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100%;
    max-height: 900px;
    z-index: -1;
}

.textSections {
    color: white;
    width: 100vw;
    height: 90vh;
    max-height: 900px;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 0;
}

.signupButton {
    color: white;
    background-color: #090157;
    border-radius: 5px;
    padding: .1%;
    width: 170px;
    max-width: 170px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 75%;
}

.signupButton a {
    text-decoration: none;
    color: white;
}

.standard {
    opacity: 0;
    visibility: hidden;
    transition: opacity 400ms ease-in-out, transform 400ms ease-in-out;
    will-change: opacity, visibility;
    min-height: 60vh;
    height: max-content;
    word-wrap: break-word;
    font-size: 15px;
    /* font-family: RobotoRegular; */
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
}

.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
    display: flex;
}

.frontImage {

    background-size: cover;
    height: 100%;
    background-position: center;
    filter: brightness(85%);
    scale: .25;

}

.atmosphereImage {

    padding-bottom: 10px;
    max-height: 300px;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 10px;

}

.imageFade {
    opacity: 0;
    transition: opacity 450ms ease-in-out, transform 900ms ease-in-out;
    will-change: opacity, visibility;
    height: 60vh;
    width: 100vw;
    background-position: center;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageFade.show {
    opacity: 1;
}

.pageTitle {
    font-size: 2em;
    color: white;
    font-weight: bolder;
    text-align: center;
    width: 50%;
    text-shadow: 2px 1px 2px black;
}

.divider {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    font-size: 25px;
    color: #090157;
    font-family: RobotoBold;
    text-transform: uppercase;
}

.component {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
    transition: opacity 750ms ease-in-out, transform 750ms ease-in-out;
    will-change: opacity, visibility;
}

.tryoutsdiv {
    position: relative;
    width: 100%;
    height: 100%;
    color: #ffffff;
    text-align: center;
    justify-content: center;
    font-size: calc(20px + .5vw);
    font-family: RobotoBold;
    -webkit-text-stroke: .5px rgb(0, 0, 0);
    z-index: 3;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.activityEntry {
    color: white;
    font-family: LatoRegular;
    font-size: 16px;
    text-align: center;
    border-radius: 5px;
    padding: 15px;
    margin-top: 15px;
    transition: opacity 0.5s ease;
    position: relative;
}

.lastActivityEntry {
    width: max-content;
    height: 10%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    color: black;
    text-align: center;
}